import { FormControl, InputLabel, MenuItem, Select, useTheme, type SelectChangeEvent } from '@mui/material'
import { Box } from '@mui/system'
import Loading from 'components/Loading'
import { useGlobals } from 'context/GlobalsContext'
import { useSession } from 'context/SessionContext'
import { type ConfidentialityLevel, type ConfidentialityLevelNumber, type Globals } from 'features/globals/types'
import { useUpdateSession } from 'features/sessions/api/updateSession'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

export const ConfidentialitySettings: React.FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const globals = useGlobals()
  const { selectedSession: session } = useSession()
  const { mutate } = useUpdateSession()
  const [level, setLevel] = useState<ConfidentialityLevel | null>(null)

  useEffect(() => {
    console.debug('ConfidentialitySettings > [globals, session]')

    if (session === null) return

    // Use session-specific confidentiality level if available,
    // otherwise use the user-specific level if available,
    // otherwise use the default level from globals.
    const levelNum: ConfidentialityLevelNumber = (
      session.settings?.confidentialityLevel ?? globals?.defaultLevel.level
    )

    // Find the level object from the level number
    let level = globals.levels.find((l) => l.level === levelNum)
    if (level === undefined) {
      console.error(`Confidentiality level ${levelNum} not found!`)
      // Fallback to default level
      level = globals.defaultLevel
    }

    setLevel(level)
  }, [globals, session])

  const handleChange = (event: SelectChangeEvent): void => {
    if (session === null) throw new Error('Session not set!')

    const newLevelNum = event.target.value
    const newLevel = globals.levels.find((l) => l.level.toString() === newLevelNum)
    if (newLevel === undefined) throw new Error(`Level ${newLevelNum} not found!`)

    const sessionUpdate = { settings: { ...session.settings, confidentialityLevel: newLevel.level } }
    mutate({ sessionId: session.id, update: sessionUpdate })
  }

  if (globals === null || session === null || level === null) {
    return <Loading />
  }

  const label = intl.formatMessage({
    id: 'app.confidentiality-level.label',
    defaultMessage: 'Confidentiality level'
  })

  return (
    <Box sx={{ marginTop: 2 }}>
      <FormControl fullWidth>
        <InputLabel
          style={{
            // Use the theme's text color to increase the contrast.
            // (Default is grayish)
            color: theme.palette.text.primary
          }}
          id="confidentiality-level-label"
        >
          {label}
        </InputLabel>
        <Select
          sx={{ width: '250px' }}
          labelId="confidentiality-level-label"
          id="confidentiality-level"
          value={level.level.toString()}
          label={label}
          onChange={handleChange}
        >
          {globals.levels.map((level, idx) => (
            <MenuItem key={idx} value={level.level.toString()}>
              {intl.formatMessage({
                id: `app.confidentiality-level.level-${level.level}.label`,
                defaultMessage: level.label
              })}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
