import { Box, useTheme } from '@mui/material'
import CopilexButtonSvg from '../assets/copilex_button.svg'

const CopilexButtonIcon = (): JSX.Element => {
  const theme = useTheme()

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default, display: 'inline-block', borderRadius: '50%' }}>
      <img
        src={CopilexButtonSvg}
        width={32}
        height={32}
        alt="Copilex"
        style={{ display: 'block' }}
      />
    </Box>
  )
}

export default CopilexButtonIcon
