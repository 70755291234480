/**
 * Hook and utility functions to get and set the assistant for a session.
 */

import { useGlobals } from 'context/GlobalsContext'
import { useSession } from 'context/SessionContext'
import { useUpdateSession } from 'features/sessions/api/updateSession'
import { useEffect, useState } from 'react'
import { type Assistant, getAssistantId } from '..'

interface UseAssistantContextProps {
  assistant: Assistant | null
  setAssistantById: (assistantId: string) => void
}

/**
 * Hook to get and set the assistant for the selected session.
 */
const useAssistant = (): UseAssistantContextProps => {
  const globals = useGlobals()
  const { selectedSession: session } = useSession()
  const updateSessionMutation = useUpdateSession()
  const [assistant, setAssistant] = useState<Assistant | null>(null)

  useEffect(() => {
    console.debug('useAssistant > [globals, session]')

    if (globals === null || session === null) return

    // Use session-specific assistant if available,
    // otherwise use the default assistant from user settings if available,
    // otherwise use the default assistant from globals.
    const newAssistantId = getAssistantId(session, globals)

    const selectedAssistant = globals.assistantsById.get(newAssistantId)
    if (selectedAssistant === undefined) {
      throw new Error(`Unknown assistant ID: ${newAssistantId}`)
    }
    setAssistant(selectedAssistant)
  }, [globals, session])

  const setAssistantById = (assistantId: string): void => {
    // Find the assistant with the given ID.
    const selectedAssistant = globals.assistantsById.get(assistantId)
    if (selectedAssistant === undefined) {
      throw new Error(`Unknown assistant ID: ${assistantId}`)
    }
    setAssistant(selectedAssistant)

    // Update the session with this new assistant.
    // FIXME: The problem with this is that it will re-fetch the session,
    // which will trigger this hook again, and the main task
    // will be reset to the default task...
    if (session === null) {
      throw new Error('Session not set, cannot set assistant!')
    }
    const sessionUpdate = { settings: { ...session.settings, assistantId: selectedAssistant.id } }
    updateSessionMutation.mutate({ sessionId: session.id, update: sessionUpdate })
  }

  return { assistant, setAssistantById }
}

export default useAssistant
