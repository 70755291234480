import type { AttachmentListItem } from 'features/documents'
import type { DocReviewIssue, DocReviewResults, IssueCategory, IssueType, OtherDocReviewResult } from '../types'

/**
 * Given doc review results, regroup them by issue category (e.g., "Internal") and type (e.g., "Typographical Errors")
 * to facilitate their display by the UI. Unstructured results (i.e., text output) are also separated.
 * @param results
 * @returns
 */
export const regroupResultsByCategory = (results: DocReviewResults): { categoryToTypeToResults: Map<IssueCategory, Map<IssueType, DocReviewIssue[]>>, unstructuredResults: OtherDocReviewResult[] } => {
  const categoryToTypeToResults = new Map<IssueCategory, Map<IssueType, DocReviewIssue[]>>()
  const unstructuredResults: OtherDocReviewResult[] = []
  results.forEach((result) => {
    if ('category' in result) {
      const category: IssueCategory = result.category
      const type: IssueType = result.type
      if (!categoryToTypeToResults.has(category)) {
        categoryToTypeToResults.set(category, new Map<IssueType, DocReviewIssue[]>())
      }
      const typeToResults = categoryToTypeToResults.get(category) as Map<IssueType, DocReviewIssue[]>
      if (!typeToResults.has(type)) {
        typeToResults.set(type, [])
      }
      const _results = typeToResults.get(type) as DocReviewIssue[]
      _results.push(result)
      typeToResults.set(type, _results)
    } else {
      unstructuredResults.push(result)
    }
  })
  return { categoryToTypeToResults, unstructuredResults }
}

/**
 * Map each attachment ID to the corresponding attachment list item
 */
export const mapAttachmentsById = (attachments: AttachmentListItem[]): Map<string, AttachmentListItem> => {
  const attachmentIdToAttachmentListItem = new Map<string, AttachmentListItem>()
  attachments.forEach((attachment) => {
    attachmentIdToAttachmentListItem.set(attachment.id, attachment)
  })
  return attachmentIdToAttachmentListItem
}
