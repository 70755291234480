import { Box, FormControl, InputLabel, MenuItem, Select, useTheme, type SelectChangeEvent } from '@mui/material'
import Loading from 'components/Loading'
import { useGlobals } from 'context/GlobalsContext'
import { useSession } from 'context/SessionContext'
import { useIntl } from 'react-intl'
import useAssistant from '../hooks/useAssistant'

export const AssistantSelect: React.FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const globals = useGlobals()
  const { selectedSession: session } = useSession()
  const { assistant, setAssistantById } = useAssistant()

  const handleChange = (event: SelectChangeEvent<string>): void => {
    if (session === null) throw new Error('Session not set!')

    setAssistantById(event.target.value)
  }

  if (assistant === null) {
    return <Loading />
  }

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          labelId='model-select-label'
          id='model-select'
          value={assistant.id}
          onChange={handleChange}
          sx={{ width: '250px' }}
          aria-label="Model"
        >
          {Array.from(globals.assistantsById).map(([assistantId, assistant], idx) => (
            <MenuItem key={idx} value={assistantId}>
              {assistant.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default AssistantSelect
