/**
 * Hook to get and update the options for the comparative analysis,
 * which are nested within the user input form state.
 */

import { AttachmentListItem } from 'features/documents'
import useListAttachments from 'features/documents/api/listAttachments'
import { useEffect } from 'react'
import useComparativeAnalysisOptions from './useComparativeAnalysisOptions'

interface UseSelectedDocuments {
  disabled: boolean
  handleChange: (index: number, attachment: AttachmentListItem | null) => void
  swapSelectedDocuments: () => void
}

const useSelectedDocuments = (): UseSelectedDocuments => {
  // The list of documents to choose from is the list of all attachments
  const attachments = useListAttachments()

  const { options, updateOptions } = useComparativeAnalysisOptions()

  // React to changes in the attachments list
  useEffect(() => {
    console.debug('> useSelectedDocuments')

    // If the attachments list now has 2 or more attachments,
    // and the user has not yet selected any, we will select the first 2
    // documents by defaults
    if (attachments !== undefined && attachments.length >= 2) {
      const prevAttachmentIds = options.attachmentsIds ?? [null, null]
      if (prevAttachmentIds[0] === null) {
        // If only the first attachment is missing,
        // select the first document that does not match the second one
        const secondAttachmentId = prevAttachmentIds[1]
        const firstAttachment = attachments.find((attachment) => attachment.id !== secondAttachmentId)
        if (firstAttachment !== undefined) {
          handleChange(0, firstAttachment)
        }
      } else if (prevAttachmentIds[1] === null) {
        // If only the second attachment is missing,
        // select the first document that does not match the first one
        const firstAttachmentId = prevAttachmentIds[0]
        const secondAttachment = attachments.find((attachment) => attachment.id !== firstAttachmentId)
        if (secondAttachment !== undefined) {
          handleChange(1, secondAttachment)
        }
      }
    }
    // Handle the case where a selected document is no longer available
    if (attachments !== undefined) {
      const attachmentIds = options.attachmentsIds
      if (attachmentIds !== undefined) {
        // Check for the first selected document
        const firstAttachmentId = attachmentIds[0]
        if (firstAttachmentId !== null && attachments.find((attachment) => attachment.id === firstAttachmentId) === undefined) {
          handleChange(0, null)
        }
        // Check for the second selected document
        const secondAttachmentId = attachmentIds[1]
        if (secondAttachmentId !== null && attachments.find((attachment) => attachment.id === secondAttachmentId) === undefined) {
          handleChange(1, null)
        }
      }
    }
  }, [attachments, options, updateOptions])

  const handleChange = (index: number, attachment: AttachmentListItem | null): void => {
    const attachmentIds = updateAttachmentsIds(options.attachmentsIds, index, attachment?.id ?? null)
    updateOptions({
      attachmentsIds: attachmentIds
    })

    // // Update the document type of the selected document as well
    // if (options.comparisonTypeId === null) {
    //   throw new Error('Comparison type is not set, cannot determine document types')
    // }
    // const docType = getDocTypesFromComparisonType(options.comparisonTypeId)[index]
    // console.debug(`Changing docType for select doc ${index} to ${docType}`)
    // updateAttachmentMutations[index]?.mutate({
    //   update: {
    //     docType
    //   }
    // })
  }

  const swapSelectedDocuments = (): void => {
    if (options.attachmentsIds[0] === null || options.attachmentsIds[1] === null) {
      return
    }
    updateOptions({
      attachmentsIds: [options.attachmentsIds[1], options.attachmentsIds[0]]
    })
  }

  const updateAttachmentsIds = (
    prevAttachmentIds: Array<string | null>,
    index: number,
    attachmentId: string | null
  ): Array<string | null> => {
    if (prevAttachmentIds.length !== 2) {
      throw new Error(`Expected an array of 2, but got ${prevAttachmentIds.length}`)
    }
    if (index < 0 || index >= prevAttachmentIds.length) {
      throw new Error(`Invalid index: ${index}`)
    }
    // Copy the array to avoid mutating the original
    const attachmentIds = prevAttachmentIds.slice()
    // Prevent the same attachment from appearing twice
    // by replacing previous occurrences by null
    attachmentIds.forEach((id, i) => {
      if (id === attachmentId && i !== index) {
        attachmentIds[i] = null
      }
    })
    attachmentIds[index] = attachmentId
    return attachmentIds
  }

  // The UI will be disabled if there are fewer than 2 attachments,
  // because we first want the user to upload all the necessary attachments.
  const disabled = attachments === undefined || attachments.length < 2

  return { disabled, handleChange, swapSelectedDocuments }
}

export default useSelectedDocuments
