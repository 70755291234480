import { Avatar, type Theme, useTheme } from '@mui/material'
import { type User, useAuth } from '../context/AuthContext'
import logoClosedBorder from '../assets/logo-closed-border.png'
import logoClosedBorderWhite from '../assets/logo-closed-border-white.png'
import { StyledAvatar, StyledBox } from './UserAvatar.styles'

// Taken from MUI documentation and modified
export function stringAvatar (name: string, palette: Theme['palette']): object {
  const _name =
    name !== undefined && name !== null && name !== '' ? name : 'Anonymous'
  const words = _name.split(' ')
  const initials: string = words
    .map((word) => (word.length > 0 ? word[0].toUpperCase() : ''))
    .slice(0, 2)
    .join('')
  const bgcolor = stringToColor(_name)
  return {
    sx: {
      color: palette.getContrastText(bgcolor),
      bgcolor
    },
    children: initials
  }
}

function stringToColor (string: string): string {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

const createAvatar = (currentUser: User, palette: Theme['palette']): JSX.Element => {
  const photoURL = currentUser.auth0User.picture
  if (photoURL !== undefined) {
    return <Avatar src={photoURL} />
  }

  return (
    <Avatar
      {...stringAvatar(currentUser.userProfile.fullName, palette)}
    />
  )
}

const UserAvatar: React.FC = () => {
  const theme = useTheme()
  const currentUser = useAuth()

  const avatar = createAvatar(currentUser, theme.palette)
  const src = (
    theme.palette.mode === 'light'
      ? logoClosedBorder
      : logoClosedBorderWhite
  )

  return (
    <StyledBox>
      {avatar}
      {/* Add a border based on Copilex logo */}
      <StyledAvatar src={src} />
    </StyledBox>
  )
}

export default UserAvatar
