import { Box, Typography } from '@mui/material'
import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { AnalysisTaskAction, type AnalysisTaskParams, type TranslateTaskParams, type UserInputForm } from 'features/user-input-form/types'
import { useIntl } from 'react-intl'
import ComparativeAnalysisTypeSelect from '../../../features/comparative-analysis/components/ComparativeAnalysisTypeSelect'
import AnalysisTaskActionSelect from './AnalysisTaskActionSelect'
import SanityCheckOptionsSelect from './SanityCheckOptionsSelect'
import SpecificAnalysisOptionsSelect from './SpecificAnalysisOptionsSelect'
import TaskSelect from './TaskSelect'
import TranslateLanguageSelect from './TranslateLanguageSelect'
import { containerSx } from './UserPromptButtons.styles'
import AssistantSelect from 'features/assistants/components/AssistantSelect'

/**
 * Once the user has picked a main task, user prompt buttons are shown
 * to allow the user to further specify options for the task.
 */
const UserPromptButtons: React.FC = () => {
  const intl = useIntl()
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const handleChange = (newUserInputForm: Partial<UserInputForm>): void => {
    console.debug('>>> Compose: UserInputForm changed: ', newUserInputForm)
    setUserInputForm(newUserInputForm)
  }

  /**
   * Helper function to get the components specific for the Translate task.
   */
  const getTranslateComponents = (): JSX.Element[] => {
    const translateComponents = []

    // Check that the task params are set.
    // They should be set at the same time as the main task is set.
    const taskParams = userInputForm.taskParams as TranslateTaskParams | undefined | null
    if (taskParams === undefined ||
      taskParams === null ||
      taskParams.sourceLang === undefined ||
      taskParams.sourceLang === null ||
      taskParams.targetLang === undefined ||
      taskParams.targetLang === null) {
      throw new Error(
        `Cannot render components because the Translate task has been selected,
        but its specific task params are not set.`
      )
    }

    translateComponents.push(
      <Typography key={1} sx={{ alignContent: 'center', minWidth: '0px' }}>
        {intl.formatMessage({
          id: 'app.translate-task-language-select.from.label',
          defaultMessage: 'From'
        })}
      </Typography>
    )
    translateComponents.push(
      <TranslateLanguageSelect
        key={2}
        isTarget={false} />
    )
    translateComponents.push(
      <Typography key={3} sx={{ alignContent: 'center', minWidth: '0px' }}>
        {intl.formatMessage({
          id: 'app.translate-task-language-select.to.label',
          defaultMessage: 'to'
        })}
      </Typography>
    )
    translateComponents.push(
      <TranslateLanguageSelect
        key={4}
        isTarget={true} />
    )

    return translateComponents
  }

  /**
   * Helper function to get the components specific for the Chat task.
   */
  const getChatComponents = (): JSX.Element[] => {
    const chatChildren: JSX.Element[] = []
    // Chat task does have an option to change the assistant,
    // to use agents other than Copilex.
    chatChildren.push(
      <Box key={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <Typography>
          {intl.formatMessage({
            id: 'app.chat-with-label',
            defaultMessage: 'Chat with:'
          })}
        </Typography>
        <AssistantSelect />
      </Box>
    )
    return chatChildren
  }

  /**
   * Helper function to get the components specific for the Analyze task.
   */
  const getAnalyzeComponents = (): JSX.Element[] => {
    const analyzeChildren = []

    const analysisActionSelectComponent = (
      <AnalysisTaskActionSelect
        key={1}
        userInputForm={userInputForm}
        onChange={handleChange} />
    )
    analyzeChildren.push(analysisActionSelectComponent)

    const taskParams = userInputForm.taskParams as AnalysisTaskParams

    if (taskParams?.action === AnalysisTaskAction.SanityCheck) {
      // List of sanity check options to choose from
      const sanityCheckOptionsComponent = (
        <SanityCheckOptionsSelect
          key={2}
          userInputForm={userInputForm}
          onChange={handleChange} />
      )
      analyzeChildren.push(sanityCheckOptionsComponent)
    } else if (taskParams?.action === AnalysisTaskAction.ComparativeAnalysis) {
      analyzeChildren.push(
        <ComparativeAnalysisTypeSelect key={2} />
      )
    } else if (taskParams?.action === AnalysisTaskAction.SpecificAnalysis) {
      // List of sanity check options to choose from
      const specificAnalysisOptionsComponent = (
        <SpecificAnalysisOptionsSelect
          key={2}
          userInputForm={userInputForm}
          onChange={handleChange} />
      )
      analyzeChildren.push(specificAnalysisOptionsComponent)
    }

    return analyzeChildren
  }

  // Construct a list of components to render,
  // based on the current state of the user input form.
  const children: JSX.Element[] = []

  // If task has already been selected, show it
  // as selected from a dropdown menu.
  const taskSelectComponent = (
    <TaskSelect
      key={0}
      userInputForm={userInputForm}
      onChange={handleChange}
    />
  )
  children.push(taskSelectComponent)

  // Add task-specific components

  // Analyze task
  if (userInputForm.mainTask === 'Analyze') {
    children.push(...getAnalyzeComponents())
  } else if (userInputForm.mainTask === 'Translate') {
    children.push(...getTranslateComponents())
  } else if (userInputForm.mainTask === 'Chat') {
    children.push(...getChatComponents())
  }

  return (
    <Box className={'user-prompt-buttons'} sx={containerSx}>
      {children}
    </Box>
  )
}

export default UserPromptButtons
