import { useContext, useEffect, type ReactNode } from 'react'
import { createContext, useState } from 'react'
import { IntlProvider } from 'react-intl'
import French from 'i18n/fr.json'
import English from 'i18n/en.json'
import { getCookies, setCookie } from 'services/cookies'

interface LocaleConfig {
  locale: string
  messages: Record<string, string>
}

export interface IntlContextData {
  localeConfig: LocaleConfig
  selectLanguage: (newLocale: string) => void
}

export const IntlContext = createContext<IntlContextData | null>(null)

interface Props {
  children: ReactNode
}

const supportedLocales: string[] = ['en', 'fr']

const langToLocaleConfig = (lang: string): LocaleConfig => {
  // Lang can be "fr-FR", "fr-BE", ... whereas our messages are just "fr" or "en"
  if (lang.startsWith('fr')) {
    return {
      locale: 'fr',
      messages: French
    }
  } else {
    // Default to English otherwise
    return {
      locale: 'en',
      messages: English
    }
  }
}

const localeToMessages: Record<string, Record<string, string>> = {
  fr: French,
  en: English
}

export const localeToLanguageName: Record<string, string> = {
  fr: 'French',
  en: 'English'
}

const IntlProviderWrapper = ({ children }: Props): JSX.Element => {
  const cookies = getCookies()
  const storedLocale: string | undefined = cookies.get('locale')

  const initLocalConfig = (
    storedLocale !== undefined
      ? {
          locale: storedLocale,
          messages: localeToMessages[storedLocale]
        }
      : langToLocaleConfig(navigator.language)
  )

  const [localeConfig, setLocaleConfig] = useState<LocaleConfig>(initLocalConfig)

  // Save locale to cookies for when the page is reloaded
  useEffect(() => {
    setCookie('locale', localeConfig.locale)
  }, [localeConfig])

  const selectLanguage = (newLocale: string): void => {
    if (!supportedLocales.includes(newLocale)) {
      console.error('Unsupported locale: ', newLocale)
      return
    }
    setLocaleConfig({
      locale: newLocale,
      messages: localeToMessages[newLocale]
    })
  }

  return (
    <IntlContext.Provider value={{ localeConfig, selectLanguage }}>
      <IntlProvider
        key={localeConfig.locale}
        locale={localeConfig.locale}
        messages={localeConfig.messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  )
}

export const useIntlContext = (): IntlContextData => { return useContext(IntlContext) as IntlContextData }

export { IntlProviderWrapper }
