import { type ComparativeAnalysisOption } from 'features/comparative-analysis/types'
import { type AttachmentListItem, type DocType } from 'features/documents/types'

export type MainTask = (
  'Chat' |
  'Answer' |
  'Search' |
  'Analyze' |
  'Draft' |
  'Summarize' |
  'Translate' |
  'Discover' |
  'No defined main task' |
  'Main task not certain')

/**
 * Default main task to be set when the user has just created a new session
 */
export const defaultMainTask: MainTask = 'Chat'

export enum AnalysisTaskAction {
  SanityCheck = 'Sanity check',
  ComparativeAnalysis = 'Comparative analysis',
  SpecificAnalysis = 'Specific analysis'
}

export enum SanityCheckOption {
  TypographicalErrors = 'Typographical errors',
  InternalCrossReferenceCheck = 'Internal cross-reference check',
  NumberingIssues = 'Numbering issues',
  Redundancies = 'Redundancies',
  InternalLogicErrors = 'Internal logic errors',
  AmbiguousTermsAndPhrases = 'Ambiguous terms and phrases',
  OmissionsOfImportantTerms = 'Omissions of important terms'
}

export enum SpecificAnalysisOption {
  RightsAndObligationAnalysis = 'Rights and Obligation Analysis',
  PerformanceAnalysis = 'Performance Analysis',
  TimeframeAnalysis = 'Time-frame Analysis',
  FinancialAnalysis = 'Financial Analysis',
  DisputeResolutionAnalysis = 'Dispute Resolution Analysis',
  InternalRiskAnalysis = 'Internal Risk Analysis',
  NegotiationAnalysis = 'Negotiation Analysis'
}

export interface AnalysisTaskParams {
  action: AnalysisTaskAction
  sanityCheckOptions?: SanityCheckOption[]
  comparativeAnalysisOptions?: ComparativeAnalysisOption
  specificAnalysisOptions?: SpecificAnalysisOption[]
}

export interface TranslateTaskParams {
  sourceLang: string
  targetLang: string
}

/**
 * User input form contains all direct input from the user (text input, attachments, selected tasks and options),
 * or inferred by the assistant.
 */
export interface UserInputForm {
  textInput: string
  attachments: AttachmentListItem[]
  docType?: DocType
  taskNature?: string
  jurisdiction?: string
  areaOfLaw?: string
  industry?: string
  mainTask?: MainTask | null
  taskParams?: AnalysisTaskParams | TranslateTaskParams
  userLanguage?: string
}
