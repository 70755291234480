import { Typography } from '@mui/material'
import { type AttachmentListItem } from 'features/documents'
import { useIntl } from 'react-intl'
import { type DocReviewIssue, type IssueType } from '../types'
import { StyledBox } from './ContextualIssuesDisplay.styles'
import DocReviewIssueDisplay from './DocReviewIssueDisplay'

interface Props {
  typeToResults: Map<IssueType, DocReviewIssue[]>
  reviewedDoc: AttachmentListItem | null
  referenceDoc: AttachmentListItem | null
  attachmentIdToAttachmentListItem: Map<string, AttachmentListItem>
}

const ContextualIssuesDisplay: React.FC<Props> = ({
  typeToResults,
  reviewedDoc,
  referenceDoc,
  attachmentIdToAttachmentListItem
}: Props) => {
  const intl = useIntl()
  return (
    <div className='contextual-issues'>
      {
        Array.from(typeToResults).map(([type, _results]: [IssueType, DocReviewIssue[]]) => {
          return (
            <StyledBox className='contextual-issues-item' key={type}>
              {/* For now, this would be "Comparative Analysis" */}
              <Typography variant='h3' className='contextual-issues-title'>
                {
                  intl.formatMessage({
                    id: `app.doc-review-results-type.${type}.title`,
                    defaultMessage: 'Comparative Analysis'
                  })
                }
              </Typography>
              <Typography className='contextual-issues-description'>
                {
                  intl.formatMessage({
                    id: `app.doc-review-results-type.${type}.description`,
                    defaultMessage: `Copilex has identified the following ${type}s between the Reviewed Document and the Reference Document.`
                  })
                }
              </Typography>
              <StyledBox className='doc-review-issues'>
              {
                _results.map((result, idx) => {
                  return (
                    <div className='doc-review-issue-item' key={idx}>
                      <DocReviewIssueDisplay
                        idx={idx}
                        result={result}
                        reviewedDoc={reviewedDoc}
                        referenceDoc={referenceDoc}
                        attachmentIdToAttachmentListItem={attachmentIdToAttachmentListItem}
                      />
                    </div>
                  )
                })
              }
              </StyledBox>
            </StyledBox>
          )
        })
      }
    </div>
  )
}

export default ContextualIssuesDisplay
