import { Box, FormControl, InputLabel, MenuItem, Select, useTheme, type SelectChangeEvent } from '@mui/material'
import { useIntlContext } from 'context/IntlProviderWrapper'
import { useIntl } from 'react-intl'

export const LocaleSelect: React.FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const { localeConfig, selectLanguage } = useIntlContext()

  const handleChange = (event: SelectChangeEvent<string>): void => {
    selectLanguage(event.target.value)
  }

  const langs = ['en', 'fr']

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel
          id="locale-select-label"
          style={{
            // Use the theme's text color to increase the contrast.
            // (Default is grayish)
            color: theme.palette.text.primary,
            // For some reason, the theme doesn't set the background color
            // to avoid having the outline strike over the text.
            // We fix this by setting the background color explicitly,
            // and also adding some padding to make it look like the
            // other input labels.
            backgroundColor: theme.palette.background.paper,
            padding: '0 5px'
          }}
        >
          {
            intl.formatMessage({
              id: 'app.locale-select.label',
              defaultMessage: 'Language'
            })
          }
        </InputLabel>
        <Select
          labelId='locale-select-label'
          id='locale-select'
          value={localeConfig.locale}
          onChange={handleChange}
          sx={{ width: '250px' }}
          aria-label="Language"
        >
          {
            langs.map((lang) => (
              <MenuItem key={lang} value={lang}>
                {
                  intl.formatMessage({
                    id: `app.locale-select.locale-${lang}.label`,
                    defaultMessage: lang
                  })
                }
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  )
}

export default LocaleSelect
