import { Typography } from '@mui/material'
import MuiMarkdown, { type Overrides } from 'mui-markdown'
import { useIntl } from 'react-intl'
import { StyledBox, userInputTypographySx } from './MessageText.styles'

interface Props {
  content: string
  showRaw?: boolean
}

const MessageText: React.FC<Props> = ({ content, showRaw = false }: Props) => {
  const intl = useIntl()

  // Create an overrides object to pass to MuiMarkdown,
  // to change its default styles which are not to our liking.
  const overrides: Overrides = {
    // When formatting the "raw" message, we want to wrap the text.
    pre: {
      props: {
        style: {
          textWrap: 'wrap'
        }
      }
    }
  }

  return (
    <StyledBox className='message-text'>
      {
        content.length > 0
          ? (
              showRaw
                ? content
                : <MuiMarkdown overrides={overrides}>{content}</MuiMarkdown>
            )
          : <Typography sx={userInputTypographySx(showRaw)}>
              <i>{
                intl.formatMessage({
                  id: 'app.message-text.no-additional-input',
                  defaultMessage: 'No additional input'
                })
              }</i>
            </Typography>
      }
    </StyledBox>
  )
}

export default MessageText
