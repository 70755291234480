import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Tooltip } from '@mui/material'
import { useIntl } from 'react-intl'
import { StyledButton } from './OpenUploadModalButton.styles'
import { useGlobals } from 'context/GlobalsContext'
import { type Globals } from 'features/globals'
import StyledIconButton from 'components/buttons/StyledIconButton'

interface Props {
  open: () => void
  uploadDisabled: boolean
  iconOnly?: boolean
}

const OpenUploadModalButton = ({ open, uploadDisabled, iconOnly = false }: Props): JSX.Element => {
  const intl = useIntl()
  const globals = useGlobals()

  const prettyAcceptedFilesList = globals.attachments.acceptedFiletypes.map((filetype) =>
    `${filetype.name} (${filetype.extensions.join(', ')})`
  ).join(', ')

  const tooltipTitle = (
    uploadDisabled
      ? intl.formatMessage({
        id: 'app.file-uploader-modal.button.tooltip.disabled',
        defaultMessage: 'Can\'t attach more files for this session'
      })
      : intl.formatMessage({
        id: 'app.file-uploader-modal.button.tooltip.enabled',
        defaultMessage: 'Upload a file. Supports {prettyAcceptedFilesList}'
      }, { prettyAcceptedFilesList })
  )

  return (
    <Tooltip title={tooltipTitle}>
      <span>
        {iconOnly
          ? <StyledIconButton
              onClick={open}
              disabled={uploadDisabled}
            >
              <AttachFileIcon />
            </StyledIconButton>
          : (
            <StyledButton
              startIcon={
                <AttachFileIcon />
              }
              onClick={open}
            >
              {
                !iconOnly && intl.formatMessage({
                  id: 'app.file-uploader-modal.button.label',
                  defaultMessage: 'Upload'
                })
              }
            </StyledButton>
            )
        }
      </span>
    </Tooltip>
  )
}

export default OpenUploadModalButton
