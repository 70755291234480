import { Box } from '@mui/material'
import { type OtherDocReviewResult } from '../types'
import MessageText from 'components/central-pane/chat/MessageText'

interface Props {
  result: OtherDocReviewResult
}

const OtherDocReviewResultDisplay: React.FC<Props> = ({ result }: Props) => {
  return (
    <Box className='other-doc-review-result'>
      <h1>{result.option}</h1>
      <MessageText content={result.result}/>
    </Box>
  )
}

export default OtherDocReviewResultDisplay
