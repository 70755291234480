import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { Box, styled, type Theme, Typography } from '@mui/material'
import StyledIconButton from 'components/buttons/StyledIconButton'
import { useGlobals } from 'context/GlobalsContext'
import FileUploader from 'features/documents/components/FileUploader'
import { type Globals } from 'features/globals'
import { useIntl } from 'react-intl'
import { capitalizeFirstLetter } from 'services/utils'
import getDocTypesFromComparisonType from '../api/getDocTypesFromComparisonType'
import useComparativeAnalysisOptions from '../hooks/useComparativeAnalysisOptions'
import useSelectedDocuments from '../hooks/useSelectedDocuments'
import DocumentSelect from './DocumentSelect'

const SwapButton: React.FC = () => {
  const { swapSelectedDocuments } = useSelectedDocuments()
  return (
    <StyledIconButton onClick={() => { swapSelectedDocuments() }}>
      <SwapHorizIcon />
    </StyledIconButton>
  )
}

const Label = ({ whichDoc }: { whichDoc: 'reviewed' | 'reference' }): JSX.Element => {
  const intl = useIntl()
  const globals = useGlobals()

  const { disabled } = useSelectedDocuments()
  const { options } = useComparativeAnalysisOptions()

  // The label color will be different as well if the UI is disabled
  const labelSx = {
    flexGrow: 0,
    flexShrink: 1,
    color: (theme: Theme) => disabled ? theme.palette.text.disabled : theme.palette.text.primary
  }

  const displayDocType = (whichDoc: 'reviewed' | 'reference'): string => {
    const index = whichDoc === 'reviewed' ? 0 : 1
    if (options.comparisonTypeId === null) {
      return ''
    }
    const docType = getDocTypesFromComparisonType(options.comparisonTypeId, globals)[index]
    return intl.formatMessage({
      id: `app.doc-type.${docType}`,
      defaultMessage: docType
    })
  }

  return (
    <Typography sx={labelSx}>
      {intl.formatMessage({
        id: `app.comparative-analysis-options-select.${whichDoc}`,
        defaultMessage: `${capitalizeFirstLetter(whichDoc)} {docType}`
      }, {
        docType: displayDocType(whichDoc)
      })}
    </Typography>
  )
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100%'
}))

const DocumentSelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: '50%',
  flexGrow: 0,
  flexShrink: 1,
  alignItems: 'center',
  gap: theme.spacing(1)
}))

/**
 * Component for selecting which attached documents to use in a comparative analysis.
 */
const ComparativeAnalysisDocumentsSelect: React.FC = () => {
  return (
    <Container id='comparative-analysis-documents-select'>
      <DocumentSelectContainer>
        <Label whichDoc={'reviewed'} />
        <DocumentSelect index={0} />
        <FileUploader iconOnly />
      </DocumentSelectContainer>
      <SwapButton />
      <DocumentSelectContainer>
        <Label whichDoc={'reference'} />
        <DocumentSelect index={1} />
        <FileUploader iconOnly />
      </DocumentSelectContainer>
    </Container>
  )
}

export default ComparativeAnalysisDocumentsSelect
