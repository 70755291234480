import { Box, MenuItem, Select, Tooltip, type SelectChangeEvent } from '@mui/material'
import { useGlobals } from 'context/GlobalsContext'
import { type Globals } from 'features/globals'
import { useIntl } from 'react-intl'
import useComparativeAnalysisOptions from '../hooks/useComparativeAnalysisOptions'
import { type ComparisonTypeId } from '../types'
import FileUploader from 'features/documents/components/FileUploader'

/**
 * Component for selecting the type of comparison to perform in a comparative analysis,
 * e.g., "Term sheet v. Contract".
 */
const ComparativeAnalysisTypeSelect: React.FC = () => {
  const intl = useIntl()
  const globals = useGlobals()
  const { options, updateOptions } = useComparativeAnalysisOptions()

  const comparisonTypes = globals.taskParams.analyze.comparativeAnalysis.comparisonTypes
  const selectedComparisonTypeId = options.comparisonTypeId

  const handleChange = (event: SelectChangeEvent<any>): void => {
    const comparisonTypeId: ComparisonTypeId = event.target.value
    updateOptions({ comparisonTypeId })
  }

  const emptyLabel = intl.formatMessage({
    id: 'app.comparative-analysis.type-select.placeholder',
    defaultMessage: 'Select comparison type'
  })

  const renderValue = (value: string): string => (
    value !== ''
      ? translatedComparisonType(value)
      : emptyLabel
  )

  const translatedComparisonType = (comparisonTypeId: ComparisonTypeId): string => (
    intl.formatMessage({
      id: `app.comparative-analysis.type-select.options.${comparisonTypeId}`,
      defaultMessage: comparisonTypeId
    })
  )

  return (
    <Box
      className={'comparative-analysis-type-select'}
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}
    >
      <Select
        // Using '' instead of null to avoid a warning from MUI
        value={selectedComparisonTypeId ?? ''}
        onChange={handleChange}
        displayEmpty
        renderValue={renderValue}
      >
        {
          comparisonTypes.map((comparisonType, index) => (
            <MenuItem key={index} value={comparisonType.id}>
              <Tooltip key={index} title={comparisonType.description} placement={'right'}>
                <span>
                  {translatedComparisonType(comparisonType.id)}
                </span>
              </Tooltip>
            </MenuItem>
          ))
        }
      </Select>
    </Box>
  )
}

export default ComparativeAnalysisTypeSelect
