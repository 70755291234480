import {
  Popover,
  Typography,
  type PopoverPosition,
  useTheme
} from '@mui/material'
import type * as React from 'react'
import { useRef, useState } from 'react'
import { useGlobals } from '../../../context/GlobalsContext'
import { type EntityType, type Tag } from 'features/anonymization/types'
import { type Globals } from 'features/globals/types'
import { isDefaultSettingTag, tagReplacementSx, tagToReplacement } from '../../../services/reviewState'
import EntityTypeOrAliasSelector from '../selectors/EntityTypeOrAliasSelector'
import { snapSelectionToWord } from '../../../services/utils'
import TopRightCloseButton from './TopRightCloseButton'
import { containerStyle, popoverSlotProps, popoverSx, typographySx } from './InputDiffSpan.styles'
import { mergeSx } from '../../../services/mergeSx'

interface Props {
  offset: number
  text: string
  tag: Tag | null
  entityTypeToActiveEntitiesCleartext: Map<EntityType, string[]>
  snap?: boolean
  onDeactivate?: (tag: Tag) => void
  onReplace?: (tag: Tag, entityType: EntityType, asAliasOf?: string) => void
  // mouseCoords: { x: number, y: number }
}

/**
 * Renders a span element that displays an element from the input diff:
 * either a part that contains no anonymized text, or a one that contains
 * anonymized text and a replacement.
 * For anonymized text, a color highlight and a popover will provide
 * information about the replacement.
 * The popover will also provide a button to deactivate that rule.
 */
const InputDiffSpan: React.FC<Props> = ({
  offset,
  text,
  tag,
  entityTypeToActiveEntitiesCleartext,
  snap,
  onDeactivate,
  onReplace
  // mouseCoords
}: Props) => {
  const theme = useTheme()
  // const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef<HTMLDivElement>(null)
  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>(undefined)
  // const [onPopover, setOnPopover] = useState(false)
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null)
  const closeTimeout = useRef<NodeJS.Timeout | null>(null)
  const [selectorOpen, setSelectorOpen] = useState(false)

  const globals = useGlobals()

  const handleMouseUp = (): void => {
    if (snap ?? true) snapSelectionToWord()
  }

  // Interleave each line of text with a <br /> tag.
  const lines = text.split('\n')
  const children = lines.map((line, idx3) =>
    (idx3 === lines.length - 1 ? line : <>{line}<br /></>)
  )

  if (tag === null) {
    return (
      <Typography
        className='input-diff-span'
        id={offset.toString()}
        sx={typographySx}
        component='span'
        onMouseUp={handleMouseUp}
      >
        {children}
      </Typography>
    )
  }

  const handlePopoverOpen = (): void => {
    if (anchorPosition === undefined) {
      const elem = popoverAnchor.current
      if (elem === null) {
        throw new Error('Popover anchor is null')
      }
      const rect = elem.getBoundingClientRect()
      setAnchorPosition({ left: rect.left, top: rect.bottom })
      // setAnchorPosition({ left: mouseCoords.x - 50, top: mouseCoords.y + 10 })
    }
  }

  const handlePopoverClose = (): void => {
    if (closeTimeout.current !== null) clearTimeout(closeTimeout.current)
    setAnchorPosition(undefined)
    setSelectorOpen(false)
  }

  const handleDeactivate = (): void => {
    handlePopoverClose()
    if (onDeactivate === undefined || tag === null) return
    onDeactivate(tag)
  }

  const handleAddExactMatch = (entityType: EntityType, asAliasOf?: string): void => {
    handlePopoverClose()
    if (onReplace === undefined || tag === null) return
    onReplace(tag, entityType, asAliasOf)
  }

  const popoverId = 'mouse-over-popover'

  const openDirection: 'left' | 'right' = (
    (anchorPosition?.left ?? 0) > (window.innerWidth / 2) ? 'left' : 'right'
  )

  const handleMouseEnter = (): void => {
    // In case we re-entered, cancel the timeout that would close the popover
    if (closeTimeout.current !== null) clearTimeout(closeTimeout.current)

    // Will open the popover after a delay,
    // unless the user leaves the tag before that.
    hoverTimeout.current = setTimeout(() => {
      handlePopoverOpen()
    }, 200)
  }

  const handleMouseLeave = (): void => {
    // If we did not stay over the tag long enough,
    // clear the timeout responsible for opening the popover
    if (hoverTimeout.current !== null) clearTimeout(hoverTimeout.current)

    // If popover was opened, will close it after a delay,
    // unless the user hovers over re-enters
    closeTimeout.current = setTimeout(() => {
      handlePopoverClose()
    }, 1000)
  }

  return (
    <div
      className='input-diff-span'
      id={offset.toString()}
      style={containerStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography
        id={offset.toString()}
        ref={popoverAnchor}
        component='span'
        aria-owns={popoverId}
        aria-haspopup="true"
        sx={mergeSx(
          tagReplacementSx(tag, globals.entityTypeToColor, theme.palette),
          typographySx
        )}
        onClick={handleDeactivate}
      >
        {children}
      </Typography>

      {/* Button in the top-right corner to remove the tag */}
      {anchorPosition !== undefined && !isDefaultSettingTag(tag) &&
        <TopRightCloseButton onClick={handleDeactivate} />
      }

      <Popover
        id={popoverId}
        sx={popoverSx}
        slotProps={popoverSlotProps}
        open={anchorPosition !== undefined}
        anchorPosition={anchorPosition}
        anchorReference="anchorPosition"
      >
        <EntityTypeOrAliasSelector
          open={selectorOpen}
          onOpen={() => { setSelectorOpen(true) }}
          onClose={handlePopoverClose}
          value={tagToReplacement(tag) ?? ''}
          openDirection={openDirection}
          cleartext={text}
          entityTypeToActiveEntitiesCleartext={entityTypeToActiveEntitiesCleartext}
          onAddExactMatch={handleAddExactMatch}
        />
      </Popover>
    </div>
  )
}

export default InputDiffSpan
