import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import { Tooltip } from '@mui/material'
import StyledIconButton from './StyledIconButton'
import EditIcon from '@mui/icons-material/Edit'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useIntl } from 'react-intl'

interface Props {
  feedback: number
  onClick: () => void
}

export const ThumbsUpButton: React.FC<Props> = ({ feedback, onClick }: Props) => {
  return <Tooltip title="Helpful answer">
    <StyledIconButton onClick={onClick}>
      {feedback === 1 ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
    </StyledIconButton>
  </Tooltip>
}

export const ThumbsDownButton: React.FC<Props> = ({ feedback, onClick }: Props) => {
  return <Tooltip title="Not helpful">
    <StyledIconButton onClick={onClick}>
      {feedback === -1 ? <ThumbDownAltIcon /> : <ThumbDownOffAltIcon />}
    </StyledIconButton>
  </Tooltip>
}

interface EditButtonProps {
  disabled?: boolean
  previouslyEdited: boolean
  onClick: () => void
}

export const EditButton: React.FC<EditButtonProps> = (
  { disabled = false, previouslyEdited, onClick }: EditButtonProps
) => {
  const intl = useIntl()
  const title = intl.formatMessage({
    id: 'app.edit-button.label',
    defaultMessage: 'Suggest a correction'
  })
  return <Tooltip title={title}>
    <span>
      <StyledIconButton disabled={disabled} onClick={onClick}>
        {previouslyEdited ? <EditOutlinedIcon /> : <EditIcon />}
      </StyledIconButton>
    </span>
  </Tooltip>
}
