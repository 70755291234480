import { MenuItem, Select, Tooltip, type SelectChangeEvent } from '@mui/material'
import { type AttachmentListItem } from 'features/documents'
import useListAttachments from 'features/documents/api/listAttachments'
import { useUpdateAttachment } from 'features/documents/api/updateAttachment'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import getDocTypesFromComparisonType from '../api/getDocTypesFromComparisonType'
import { useGlobals } from 'context/GlobalsContext'
import { type Globals } from 'features/globals'
import useComparativeAnalysisOptions from '../hooks/useComparativeAnalysisOptions'
import FilenameWithIcon from 'components/FilenameWithIcon'
import useSelectedDocuments from '../hooks/useSelectedDocuments'

interface Props {
  index: number
  // attachmentId: string | null
  // onChange: (attachment: AttachmentListItem | null) => void
  // disabled: boolean
}

/**
 * A dropdown menu for selecting one of the uploaded documents.
 */
export const DocumentSelect: React.FC<Props> = ({
  index
}: Props) => {
  const intl = useIntl()
  const globals = useGlobals()
  const { options } = useComparativeAnalysisOptions()
  const { disabled, handleChange: handleDocChange } = useSelectedDocuments()

  // The list of documents to choose from is the list of all attachments
  const attachments = useListAttachments()

  const attachmentId = options.attachmentsIds[index]
  const updateAttachmentMutation = useUpdateAttachment({ attachmentId: attachmentId !== null ? attachmentId : undefined })

  useEffect(() => {
    console.debug('> DocumentSelect')

    if (attachmentId === null) {
      return
    }

    // Update the doc type for the selected document,
    // based on the comparison type selected by the user
    if (options.comparisonTypeId === null) {
      throw new Error('Comparison type is not set, cannot determine document types')
    }
    const docType = getDocTypesFromComparisonType(options.comparisonTypeId, globals)[index]
    // Test if the docType is already set and different from the new one
    const currentDocType = attachments?.find((attachment) => attachment.id === attachmentId)?.docType
    if (currentDocType !== docType) {
      console.debug(`Changing docType for select doc ${index} from ${currentDocType ?? '(undefined)'} to ${docType}`)
      updateAttachmentMutation.mutate({
        update: {
          docType
        }
      })
    }
  }, [options, index, attachmentId, attachments])

  const handleChange = (event: SelectChangeEvent<string>): void => {
    if (attachments === undefined) {
      return
    }
    const newAttachmentId = attachments.find((attachment) => attachment.id === event.target.value)
    if (newAttachmentId === undefined) {
      handleDocChange(index, null)
    } else {
      handleDocChange(index, newAttachmentId)
    }
  }

  const tooltip = (
    disabled
      ? intl.formatMessage({ id: 'app.comparative-analysis.attachment-select-tooltip.disabled', defaultMessage: 'Please upload two documents first' })
      : intl.formatMessage({ id: 'app.comparative-analysis.attachment-select-tooltip.enabled', defaultMessage: 'Select a document' })
  )

  return (
    <Tooltip
      title={tooltip}
    >
      <Select
        labelId='attachment-select-label'
        id='attachment-select'
        value={attachmentId ?? ''}
        onChange={handleChange}
        aria-label="Attachment"
        disabled={disabled}
        sx={{
          // This is a workaround to avoid the select menu changing its width
          // depending on the selected item, and overflowing the parent container.
          // There is probably a better way to do this...
          width: '350px'
        }}
      >
        {Array.from(attachments ?? []).map((attachment, idx) => (
          <MenuItem key={idx} value={attachment.id}>
            <FilenameWithIcon filename={attachment.filename}/>
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  )
}

export default DocumentSelect
