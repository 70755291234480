import {
  type SxProps,
  TableCell,
  type Theme,
  styled,
  Box,
  IconButton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { type ReviewStateItem, posAnonymizeStatusToBooleanOrNull } from '../../../services/reviewState'
import { forwardRef } from 'react'

const reviewTableCellSx = {
  padding: '8px 4px'
}
export const ReviewTableCell = styled(TableCell)(reviewTableCellSx)
export const SwitchTableCell = styled(TableCell)(
  { ...reviewTableCellSx, display: 'table-cell', textAlign: 'center' }
)

export const ReviewTableCellContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

export const aliasItemTypographySx = (item: ReviewStateItem): SxProps<Theme> => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 0.5,
  color: (
    posAnonymizeStatusToBooleanOrNull(item.posAnonymizeStatus) === false
      ? 'grey'
      : 'text.primary'
  )
})

export const AddIconButton = forwardRef<HTMLButtonElement, any>(function AddIconButton (props, ref) {
  return (
    <IconButton
      size="small"
      sx={{ ml: 1, color: 'primary.main' }}
      ref={ref}
      {...props}
    >
      <AddIcon/>
    </IconButton>
  )
})

export const CleartextContainer = styled(Box)(() => ({
  // Limit the width of the text to prevent overflow of the table (max 600px)
  maxWidth: '200px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

export const cleartextTypographySx = (notAnonymized: boolean, colorMode: 'light' | 'dark'): SxProps<Theme> => ({
  color: (notAnonymized ? 'disabled' : 'text.primary'),
  // Truncate text with ellipsis
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // When hovering, show the full text (like a tooltip)
  // (based on https://stackoverflow.com/a/52930448/2320087)
  '&:hover': {
    overflow: 'visible',
    '& span': {
      position: 'relative',
      backgroundColor: (colorMode === 'dark' ? 'black' : 'white'),
      // boxShadow: '0 0 4px 0 white',
      borderRadius: '1px',
      zIndex: 1
    }
  }
})
