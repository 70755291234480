import { type AttachmentsAndUploads } from 'features/documents'
import { AnalysisTaskAction, type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form/types'

/**
 * Check if the input is valid for sending a message.
 */
export const isValidInput = (
  input: string,
  userInputForm: Partial<UserInputForm>,
  attachments: AttachmentsAndUploads
): boolean => {
  // Not valid if an upload is in progress.
  // This corresponds to our pending list, excluding the failed uploads.
  const pendingUploadsNotFailed = attachments.pending.filter((upload) => upload.status !== 'FAILURE')
  if (pendingUploadsNotFailed.length > 0) return false

  // Input must be non-empty
  if (input.trim().length > 0) return true

  // Input may be left blank if user has selected a task, and action and some options
  if (userInputForm.mainTask === undefined || userInputForm.mainTask === null) return false
  if (userInputForm.mainTask === 'Analyze') {
    if (attachments.uploaded.length === 0) return false
    const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined
    if (taskParams?.action === undefined) return false
    switch (taskParams.action) {
      case AnalysisTaskAction.SanityCheck:
        if (taskParams.sanityCheckOptions === undefined) return false
        break
      case AnalysisTaskAction.ComparativeAnalysis:
        if (
          taskParams.comparativeAnalysisOptions === undefined
        ) {
          return false
        }
        // eslint-disable-next-line no-case-declarations
        const attachmentsIds: Array<string | null> | null = (
          taskParams.comparativeAnalysisOptions?.attachmentsIds ?? null
        )
        if (
          attachmentsIds === null || attachmentsIds.some((id: string | null) => id === null)
        ) {
          return false
        }
        break
      case AnalysisTaskAction.SpecificAnalysis:
        if (taskParams.specificAnalysisOptions === undefined) return false
        break
    }
    return true
  }

  // For Summarize or Translate, input is only required if no attachments are present
  if (userInputForm.mainTask === 'Summarize' || userInputForm.mainTask === 'Translate') {
    if (attachments.uploaded.length === 0) return false
    return true
  }

  // For all other tasks, input is required if no attachments are present
  return false
}
