import { type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form/types'
import { type DocReviewResults } from '../types'
import ContextualIssuesDisplay from './ContextualIssuesDisplay'
import { StyledBox } from './DocReviewResultsDisplay.styles'
import InternalIssuesDisplay from './InternalIssuesDisplay'
import OtherDocReviewResultDisplay from './OtherDocReviewResultDisplay'
import { mapAttachmentsById, regroupResultsByCategory } from '../api'

interface Props {
  results: DocReviewResults
  /**
   * The UserInputForm contains parameters that we need to use when displaying in the result,
   * such as which attachment was the reviewed or reference document,
   * and what their corresponding filenames are.
   */
  userInputForm: UserInputForm
}

const DocReviewResultsDisplay: React.FC<Props> = ({ results, userInputForm }: Props) => {
  const attachmentIdToAttachmentListItem = mapAttachmentsById(userInputForm.attachments)

  // Extract information specific to comparative analysis
  const comparativeAnalysisOptions = (
    (userInputForm?.taskParams as AnalysisTaskParams)?.comparativeAnalysisOptions ?? null
  )
  const reviewedDocId = comparativeAnalysisOptions?.attachmentsIds[0] ?? null
  const referenceDocId = comparativeAnalysisOptions?.attachmentsIds[1] ?? null
  // Get the corresponding AttachmentListItem object
  const reviewedDoc = reviewedDocId !== null ? attachmentIdToAttachmentListItem.get(reviewedDocId) : null
  const referenceDoc = referenceDocId !== null ? attachmentIdToAttachmentListItem.get(referenceDocId) : null
  if (reviewedDoc === undefined || referenceDoc === undefined) {
    throw new Error('Could not find attachment list item for the reviewed or reference document in the attachment list')
  }

  // Group the results to display them by category and type
  const { categoryToTypeToResults, unstructuredResults } = regroupResultsByCategory(results)

  const internalTypeToResults = categoryToTypeToResults.get('Internal')
  const contextualTypeToResults = categoryToTypeToResults.get('Contextual')

  return (
    <StyledBox className='doc-review-results'>
      {/*
        Display structured results first, if any.
        Internal issues and contextual issues both have
        their specific components to display
      */}
      {
        internalTypeToResults !== undefined && (
          <InternalIssuesDisplay typeToResults={internalTypeToResults} />
        )
      }
      {
        contextualTypeToResults !== undefined && (
          <ContextualIssuesDisplay
            typeToResults={contextualTypeToResults}
            reviewedDoc={reviewedDoc}
            referenceDoc={referenceDoc}
            attachmentIdToAttachmentListItem={attachmentIdToAttachmentListItem}
          />
        )
      }
      {/* Display unstructured results, if any */}
      {
        unstructuredResults.length > 0 && (
          <div className='doc-review-other-results'>
            {
              unstructuredResults.map((result, idx) => {
                return (
                  <div className='doc-review-other-results-item' key={idx}>
                    <OtherDocReviewResultDisplay result={result} />
                  </div>
                )
              })
            }
          </div>
        )
      }
    </StyledBox>
  )
}

export default DocReviewResultsDisplay
