// Context provider for constants provided by the API /globals route
import { type ReactNode, createContext, useEffect, useState, useContext } from 'react'
import { getGlobals } from 'features/globals/api/getGlobals'
import { type Globals } from 'features/globals/types'
import { useAuth0 } from '@auth0/auth0-react'

export const GlobalsContext = createContext<Globals | null>(null)

interface Props {
  children: ReactNode
}

/**
 * Fetches and provides global constants from the API
 */
export const GlobalsProvider = ({ children }: Props): JSX.Element => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [globals, setGlobals] = useState<Globals | null>(null)

  useEffect(() => {
    console.debug('> GlobalsProvider [isAuthenticated]')

    // Wait for user to be authenticated
    if (!isAuthenticated) {
      return
    }

    // Get access token and fetch globals
    void getAccessTokenSilently().then(async (token) =>
      await getGlobals(token)
    ).then(
      setGlobals
    ).catch(
      console.error
    )
  }, [isAuthenticated])

  if (globals === null) {
    return <></>
  }

  return (
    <GlobalsContext.Provider value={globals}>
      {children}
    </GlobalsContext.Provider>
  )
}

export const useGlobals = (): Globals => { return useContext(GlobalsContext) as Globals }
