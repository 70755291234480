import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DifferenceIcon from '@mui/icons-material/Difference'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ForumIcon from '@mui/icons-material/Forum'
import GradingIcon from '@mui/icons-material/Grading'
import SearchIcon from '@mui/icons-material/Search'
import SummarizeIcon from '@mui/icons-material/Summarize'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import TranslateIcon from '@mui/icons-material/Translate'
import { Box, type ButtonProps, Divider, useTheme } from '@mui/material'
import { outlineButtonSx } from 'components/buttons/OutlinedButton'
import CopilexButtonIcon from 'components/CopilexButtonIcon'
import { useGlobals } from 'context/GlobalsContext'
import { type ComparativeAnalysisOption, type ComparisonTypeId } from 'features/comparative-analysis'
import getSelectedComparativeAnalysisOptions from 'features/comparative-analysis/api/getComparativeAnalysisOptions'
import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { AnalysisTaskAction, type AnalysisTaskParams, defaultMainTask, type UserInputForm } from 'features/user-input-form/types'
import { type MenuItemData, NestedDropdown } from 'mui-nested-menu'
import { useIntl } from 'react-intl'
import { TaskSelectContainer } from './TaskSelect.styles'

export interface TaskEntry {
  label: string
  icon: JSX.Element
  showSeparator: boolean
  userInputForm: Partial<UserInputForm>
  disabled?: boolean
}

// For each task, describe them with a label, icon and partial user input form.
// The partial user input form is used to match the task to the provided user input form,
// and to update the user input form when the task is selected.
export const tasks: TaskEntry[] = [
  {
    label: 'Chat',
    icon: <ForumIcon />,
    showSeparator: true,
    userInputForm: {
      mainTask: 'Chat',
      taskParams: undefined
    }
  },
  {
    label: 'Analyze',
    icon: <GradingIcon />,
    showSeparator: true,
    userInputForm: {
      mainTask: 'Analyze',
      taskParams: undefined
    }
  },
  {
    label: 'Compare',
    icon: <DifferenceIcon />,
    showSeparator: false,
    userInputForm: {
      // Not yet implemented
      mainTask: undefined,
      taskParams: undefined
    },
    disabled: true
  },
  {
    label: 'Draft',
    icon: <EditNoteIcon />,
    showSeparator: false,
    userInputForm: {
      mainTask: 'Draft',
      taskParams: undefined
    }
  },
  {
    label: 'Summarize',
    icon: <SummarizeIcon />,
    showSeparator: false,
    userInputForm: {
      mainTask: 'Summarize',
      taskParams: undefined
    }
  },
  {
    label: 'Translate',
    icon: <TranslateIcon />,
    showSeparator: true,
    userInputForm: {
      mainTask: 'Translate',
      taskParams: {
        sourceLang: 'all',
        targetLang: 'en'
      }
    }
  },
  {
    label: 'Redact',
    icon: <TextSnippetIcon />,
    showSeparator: false,
    userInputForm: {
      // Not yet implemented
      mainTask: undefined,
      taskParams: undefined
    },
    disabled: true
  },
  {
    label: 'Search',
    icon: <SearchIcon />,
    showSeparator: false,
    userInputForm: {
      mainTask: 'Search',
      taskParams: undefined
    },
    disabled: true
  }
]

export const mainTaskToLabel = (mainTask: string): string => {
  const task = tasks.find((task) => task.userInputForm.mainTask === mainTask)
  if (task === undefined) {
    throw new Error(`Task with mainTask ${mainTask} not found`)
  }
  return task.label
}

const getSelectedTaskEntry = (userInputForm: Partial<UserInputForm>): TaskEntry => {
  const taskEntry = tasks.find((task) => {
    const { mainTask: mainTaskOrNullOrUndefined } = userInputForm
    const mainTask = (
      mainTaskOrNullOrUndefined === null || mainTaskOrNullOrUndefined === undefined
        ? defaultMainTask
        : mainTaskOrNullOrUndefined
    )
    return task.userInputForm.mainTask === mainTask
  })
  if (taskEntry === undefined) {
    throw new Error(`Task with mainTask ${userInputForm.mainTask ?? '(undefined)'} not found`)
  }
  return taskEntry
}

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

const TaskSelect: React.FC<Props> = () => {
  const intl = useIntl()
  const globals = useGlobals()
  const theme = useTheme()
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const selectedTaskEntry = getSelectedTaskEntry(userInputForm)
  const mainTask = selectedTaskEntry.userInputForm.mainTask
  // Main task should never be undefined or null (it is only the case for disabled main task options
  // which should not be selectable)
  if (mainTask === undefined || mainTask === null) {
    throw new Error('Main task is undefined')
  }

  const translateMainTaskLabel = (label: string): string => {
    return intl.formatMessage({
      id: `app.main-task.${label}`,
      defaultMessage: label
    })
  }

  const translateTaskGroupLabel = (label: string): string => {
    return intl.formatMessage({
      id: `app.task-group.${label}`,
      defaultMessage: label
    })
  }

  const contractAnalysisLabelText = intl.formatMessage({
    id: 'app.analyze-task.sub-task.Contract analysis',
    defaultMessage: 'Contract analysis'
  }).toLocaleUpperCase()

  const updateAnalysisTaskParams = (taskParams: AnalysisTaskParams): void => {
    setUserInputForm({
      ...userInputForm,
      mainTask: 'Analyze',
      taskParams: {
        ...userInputForm.taskParams,
        ...taskParams
      }
    })
  }

  const setComparativeType = (comparisonTypeId: ComparisonTypeId | null): void => {
    const options: ComparativeAnalysisOption = getSelectedComparativeAnalysisOptions(userInputForm)
    updateAnalysisTaskParams({
      action: AnalysisTaskAction.ComparativeAnalysis,
      comparativeAnalysisOptions: {
        ...options,
        comparisonTypeId
      }
    })
  }

  const comparisonTypes = globals.taskParams.analyze.comparativeAnalysis.comparisonTypes

  const analyzeSubTasksIcon: JSX.Element = <GradingIcon />

  // For the label to display, there is an exception for the Analyze task:
  // it has sub-tasks, which we want to display instead of the main task
  // But for now, only "Contract analysis" is available for now,
  // hence the hard-coded value
  const selectedTaskLabel = (
    selectedTaskEntry.label !== 'Analyze'
      ? translateMainTaskLabel(selectedTaskEntry.label).toUpperCase()
      : contractAnalysisLabelText
  )

  const getMenuItemDataForMainTaskLabel = (label: string): MenuItemData => {
    // Get the corresponding task entry for the label
    const selectedTaskEntry = tasks.find((task) => task.label === label)
    if (selectedTaskEntry === undefined) {
      throw new Error(`Task with label ${label} not found`)
    }
    return {
      label: translateMainTaskLabel(label),
      leftIcon: selectedTaskEntry.icon,
      callback: () => {
        setUserInputForm({
          ...userInputForm,
          ...selectedTaskEntry.userInputForm
        })
      },
      disabled: selectedTaskEntry.disabled ?? false
    }
  }

  /**
   * Menu items data for main tasks, sub-tasks and their actions.
   * For example, "Contract analysis" -> "Comparative analysis" -> "Term sheet vs. Contract"
   */
  const menuItemsData: MenuItemData = {
    label: selectedTaskLabel,
    leftIcon: selectedTaskEntry.icon,
    items: [
      getMenuItemDataForMainTaskLabel('Chat'),
      getMenuItemDataForMainTaskLabel('Search'),
      {
        // Analyze task is a special case, as it has sub-tasks
        // that we can select from this menu directly, instead of
        // having to select 'Analyze' first and then select the sub-task
        // through other menus.
        ...getMenuItemDataForMainTaskLabel('Analyze'),
        items: [
          {
            label: intl.formatMessage({
              id: 'app.analyze-task.sub-task.Contract analysis',
              defaultMessage: 'Contract analysis'
            }),
            leftIcon: analyzeSubTasksIcon,
            items: Object.values(AnalysisTaskAction).map((action) => (
              {
                label: intl.formatMessage({
                  id: `app.analyze-task.contract-analysis.action.${action}`,
                  defaultMessage: action
                }),
                leftIcon: analyzeSubTasksIcon,
                callback: () => {
                  updateAnalysisTaskParams({
                    action
                  })
                },
                items: (
                  action === AnalysisTaskAction.ComparativeAnalysis
                    ? comparisonTypes.map((comparisonType) => (
                      {
                        label: intl.formatMessage({
                          id: `app.comparative-analysis.type-select.options.${comparisonType.id}`,
                          defaultMessage: comparisonType.id
                        }),
                        leftIcon: <ContentCopyIcon />,
                        callback: () => {
                          setComparativeType(comparisonType.id)
                        }
                      }
                    ))
                    : undefined
                )
              }
            ))
          },
          {
            label: intl.formatMessage({
              id: 'app.analyze-task.sub-task.Brief analysis',
              defaultMessage: 'Brief analysis'
            }),
            leftIcon: analyzeSubTasksIcon,
            disabled: true
          },
          {
            label: intl.formatMessage({
              id: 'app.analyze-task.sub-task.Doc review',
              defaultMessage: 'Doc review'
            }),
            leftIcon: analyzeSubTasksIcon,
            disabled: true
          },
          {
            label: intl.formatMessage({
              id: 'app.analyze-task.sub-task.Other doc analysis',
              defaultMessage: 'Other doc analysis'
            }),
            leftIcon: analyzeSubTasksIcon,
            disabled: true
          }
        ]
      },
      {
        label: translateTaskGroupLabel('Generate'),
        leftIcon: <EditNoteIcon />,
        items: [
          getMenuItemDataForMainTaskLabel('Draft'),
          getMenuItemDataForMainTaskLabel('Summarize'),
          getMenuItemDataForMainTaskLabel('Translate'),
          getMenuItemDataForMainTaskLabel('Redact')
        ]
      }
    ]
  }

  const buttonProps: Partial<ButtonProps> = {
    // variant: 'outlined',
    startIcon: (
      // Combine the Copilex button icon with the selected task icon
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <CopilexButtonIcon />
        {selectedTaskEntry.icon}
      </Box>
    ),
    sx: {
      ...outlineButtonSx(theme),
      variant: 'none',
      paddingLeft: theme.spacing(1)
    },
    endIcon: null
  }

  return (
    <TaskSelectContainer>
      <NestedDropdown
        menuItemsData={menuItemsData}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
        ButtonProps={buttonProps}
      />
      {/*
        For tasks that have some options displayed next to this component,
        we need to add a separator to visually separate them.
      */}
      {selectedTaskEntry.showSeparator && <Divider orientation="vertical" flexItem />}
    </TaskSelectContainer>
  )
}

export default TaskSelect
