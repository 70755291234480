import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { forwardRef } from 'react'

export const BackIconButton = forwardRef<HTMLButtonElement, any>(function BackIconButton (props, ref) {
  return (
    <IconButton
      {...props}
      ref={ref}
    >
      <ArrowBackIcon />
    </IconButton>
  )
})

export default BackIconButton
