import { type Session } from 'features/sessions'
import { type AssistantSettings } from './types'
import { type Globals } from 'features/globals'

export * from './types'

/**
 * Ugly hack to convert the assistantId to the settings object.
 * Fix this by refactoring how we handle assistant settings!
 */
export const assistantIdToSettings = (assistantId: string): AssistantSettings => {
  if (assistantId.startsWith('copilex')) {
    return {
      agentType: 'copilex',
      modelName: 'dummy'
    }
  }
  return {
    agentType: 'conversation',
    modelName: assistantId
  }
}

export const getAssistantId = (session: Session, globals: Globals): string => (
  session.settings?.assistantId ?? globals.defaultAssistantId
)
