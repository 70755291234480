import Box from '@mui/material/Box'
import { ListSessions } from 'features/sessions/components/ListSessions'
import NewSession from 'features/sessions/components/NewSession'
import type React from 'react'
import { useIntl } from 'react-intl'
import { useSwipeable } from 'react-swipeable'
import PaneSection from '../PaneSection'
import { ConfidentialitySettings } from './ConfidentialitySettings'
import { LeftPaneContainer, assistantConfigurationContainerSx } from './LeftPane.styles'
import LocaleSelect from './LocaleSelect'
import { UserProfile } from './UserProfile'

interface LeftPaneProps {
  onClose?: () => void
}

export const LeftPane: React.FC<LeftPaneProps> = (
  {
    onClose
  }
) => {
  const intl = useIntl()
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => { onClose?.() }
  })

  return (
    <LeftPaneContainer className='left-pane' {...swipeHandlers}>
      {/* User profile */}
      <UserProfile />

      {/* Assistant configuration */}
      <PaneSection title={
        intl.formatMessage({
          id: 'app.left-pane.assistant-configuration',
          defaultMessage: 'Assistant configuration'
        })}
      >
        <Box sx={assistantConfigurationContainerSx}>
          <ConfidentialitySettings />
          <LocaleSelect />
        </Box>
      </PaneSection>

      {/* Sessions */}
      <PaneSection title={
        intl.formatMessage({
          id: 'app.left-pane.history-section.title',
          defaultMessage: 'History'
        })
      } />
      <NewSession />
      <ListSessions />
    </LeftPaneContainer>
  )
}
