import { Box, Table, TableBody, TableContainer, TableRow, Typography } from '@mui/material'
import { type DocReviewIssue, type Evidence, type IssueType } from '../types'
import CorrectionsDisplay from './CorrectionsDisplay'
import CriticalityDisplay from './CriticalityDisplay'
import EvidenceDisplay from './EvidenceDisplay'
import { DetailsCell, InternalIssueContainer, InternalIssuesContainer, LocationCell } from './InternalIssuesDisplay.styles'

interface Props {
  typeToResults: Map<IssueType, DocReviewIssue[]>
}

/**
 * Displays the results of an internal analysis,
 * which is a list of issues found for each issue type that was analyzed.
 */
const InternalIssuesDisplay: React.FC<Props> = ({
  typeToResults
}: Props) => {
  const getOneLocationFromEvidences = (evidences: Evidence[]): string | null => {
    if (evidences.length === 0) {
      return null
    }
    let location = null
    evidences.forEach((evidence) => {
      if (evidence.location !== null && evidence.location !== '') {
        location = evidence.location
      }
    })
    return location
  }

  return (
    <InternalIssuesContainer className='internal-issues'>
      {Array.from(typeToResults).map(([type, results]) => {
        return (
          <InternalIssueContainer key={type}>
            <Typography variant='h4'>{type}</Typography>
            <Typography>
              {`Copilex has identified ${results.length} ${type}(s) in the Reviewed Document.`}
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  {
                    results.map((result, idx) => (
                      <TableRow key={idx}>
                        <LocationCell>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1
                          }}>
                            {
                              result.criticality !== null && result.criticality !== undefined && (
                                <CriticalityDisplay criticality={result.criticality} />
                              )
                            }
                            {getOneLocationFromEvidences(result.evidences) ?? 'N/A'}
                          </Box>
                        </LocationCell>
                        <DetailsCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            {/** Put the corrections if any, otherwise put the explanation */}
                            {
                              result.evidences.map((evidence, idx) => (
                                <EvidenceDisplay
                                  key={idx}
                                  evidence={evidence}
                                  // Do not display the location for internal issues,
                                  // because usually there is only one evidence
                                  // and the location is already displayed in the
                                  // location cell
                                  displayLocation={false}
                                />
                              ))
                            }
                            {
                              // If there are corrections, display them instead of the explanation
                              result.corrections !== null
                                ? <CorrectionsDisplay corrections={result.corrections} />
                                : result.explanation
                            }
                          </Box>
                        </DetailsCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </InternalIssueContainer>
        )
      })}
    </InternalIssuesContainer>
  )
}

export default InternalIssuesDisplay
